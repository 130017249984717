import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import { CASE_STUDIES } from "../partials/CaseStudyOverview";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import Cta from "../partials/Cta";
import React from "react";
import {
  CogIcon,
  CubeIcon,
  SparklesIcon,
  ChartPieIcon,
  AcademicCapIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import CaseStudyStanford from "../partials/CaseStudyStanford";
import { StaticImage } from "gatsby-plugin-image";

import SvgPersonWorkforce from "../assets/icons/person-workforce.svg";
import SvgProductDataCompliance from "../assets/icons/product-data-compliance.svg";
import SvgProductData from "../assets/icons/product-data.svg";
import SEO from "../components/seo";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Manage",
    description: "Add and remove collaborators and share tasks with ease.",
    bullets: [
      "Automated workflows and task distribution",
      "Priority queues for annotation & review",
      "Granular access-control",
    ],
    image: (
      <SvgPersonWorkforce
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Workforce person"}
      />
    ),
  },
  {
    name: "Monitor",
    description:
      "Dashboard and tools to keep tabs on feature difficulty, throughput, and quality.",
    bullets: [
      "Identify sub-standard performers",
      "Discover hard-to-label features",
      "Ensure effective labeling instructions",
    ],
    image: (
      <SvgProductDataCompliance
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Data compliance"}
      />
    ),
  },
  {
    name: "Deliver",
    description: "Set up custom notifications for uninterrupted delivery.",
    bullets: [
      "Inactivity alerts",
      "Automated progress reports",
      "Quality assurance",
    ],
    image: (
      <SvgProductData
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Product data"}
      />
    ),
  },
];

const featuresGrid = [
  {
    name: "Flexible tools",
    description:
      "Our software supports a wide variety of medical image modalities. Consolidate all your data operations in a single platform.",
    icon: CubeIcon,
  },
  {
    name: "Collaborative data analysis",
    description:
      "Map experts to specific tasks. Define expert review pipelines. Role-based access control, annotator performance tracking, and dynamic task queues.",
    icon: AcademicCapIcon,
  },
  {
    name: "Configurable label editor",
    description:
      "Set up your own label structures with infinitely nested attributes and hierarchical relationships. Apply nested classifications and preserve conditional relationships between features.",
    icon: CogIcon,
  },
  {
    name: "Quality",
    description:
      "Create custom annotation & review pipelines with our intuitive interface. Discover poorly performing annotators using our performance dashboards, benchmark & consensus features.",
    icon: SparklesIcon,
  },
  {
    name: "Model-assisted labeling",
    description:
      "Use our native micro-model technology to reduce the manual annotation burden and pivot human supervision from labeling to quality control.",
    icon: LightningBoltIcon,
  },
  {
    name: "Visualise",
    description:
      "Reduce time to production by spotting data biases and imbalances early. Discover & visualise errors in your datasets.",
    icon: ChartPieIcon,
  },
];

export default function ClinicalOperations({ location }) {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={"The AI-powered platform for clinical operations teams"}
        description={
          "Facilitate seamless collaboration between annotation teams, medical professionals, and machine learning engineers."
        }
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={CASE_STUDIES.KINGS}
        link={`https://www.thieme-connect.com/products/ejournals/abstract/10.1055/a-1373-4799`}
        title={"How it works"}
        header={"Streamline clinical data operations"}
        description={
          <>
            Setup your data pipeline to be aligned for regulatory use right from
            the very beginning with our fully audited annotation system. Keep
            your processes 100% in your control, and 100% compliant.
          </>
        }
        useCases={useCases}
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"Features"}
        header={"Scalable clinical data pipelines"}
        description={
          "Encord's expert review workflows will accommodate multiple passes of quality control to ensure your labels meet the highest possible medical standards."
        }
        features={featuresGrid}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={"Invest"}
        header={"Our investors 2"}
        description={"Our investors"}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/uses-performance.webp"}
            width={850}
            alt="User performance"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/quality-grid.png"}
            width={550}
            alt="Quality grid"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../assets/images/review-json.png"}
            width={700}
            alt={"Png review json"}
          />
        }
        headerOne={"Granular performance monitoring"}
        headerTwo={"Quality control"}
        headerThree={"Expert review"}
        descriptionOne={
          "Make the most of your clinical resources by easily spotting issues and inefficiencies. Discover root-causes for declining annotator throughput and quality in complex label structures."
        }
        descriptionTwo={
          "Define and monitor quality control processes in complex label tasks. Automate quality control in assessing potential label errors to reduce clinical costs and improve data delivery."
        }
        descriptionThree={
          "Encord supports purpose-built workflows for domain experts to drive specialised quality control."
        }
      />

      <br />
      <CaseStudyStanford />
      <br />

      <Cta
        location={location}
        pageName={"clinical operations page"}
        ctaText={"Auditable annotation software for clinical teams"}
      />
    </Layout>
  );
}

export const Head = () => (
  <SEO
    title="Clinical Annotation and Data Management for Teams | Encord"
    description="Encord’s fully auditable annotation system improves collaboration among medical imagery annotation teams, medical professionals, and ML engineers."
  />
);
